import $ from "jquery";
import 'lightbox2';
import '../../global/js/cms.js';

$(function() {
    let currentFraction = 'warlock';
    $('.horse-face').click(function() {
        let fraction = $(this).attr('id').split('-')[1];

        if(fraction != currentFraction) {
            $('.horse-face-creature[data-fraction="'+ currentFraction +'"]').fadeOut();
            $('.horse-face-creature[data-fraction="'+ fraction +'"]').fadeIn();
            currentFraction = fraction;
        }
    });
});
